/* eslint-disable @next/next/no-img-element */

import { useEffect } from 'react'

const images = {
  domains: ['cdn.shopify.com', 'cdn.shopifycdn.net', 'www.facebook.com'],
  deviceSizes: [375, 767, 1023, 1279, 1439, 1539, 1919],
}

const Picture = ({
  className = '',
  imgClassName = '',
  source = '',
  quality = 2,
  loading = 'lazy',
  alt = '',
  children = null,
  onLoadFun = null,
  customDeviceSizes = null,
  customSize = null,
  ...rest
}) => {
  useEffect(() => {}, [])

  const imgOnLoadFun = () => {
    if (onLoadFun) onLoadFun()
  }

  const pictureLoader = ({ source }) => {
    if (typeof source !== 'string' || !source) return []
    const deviceSizes = customDeviceSizes ||
      images?.deviceSizes || [360, 768, 1024, 1200, 1539, 1920]
    let srcArr
    try {
      srcArr = source
        .replace(/[\r\n]/g, '')
        .replace(/\s\s+/g, ' ')
        .split(',')
        .map((item) => item.split(' ').filter(Boolean))
    } catch (error) {
      console.log(error)
      return []
    }
    const sourceList = deviceSizes.map((width) => {
      return sourceSrcSet({ srcArr, width })
    })

    const img = imgSrcSetsize({ src: srcArr[0][0], width: 1920 })

    sourceList.push(
      <img
        key="default"
        src={img}
        loading={loading}
        alt={alt || ''}
        className={`w-full ${imgClassName}`}
        onLoad={imgOnLoadFun()}
      />
    )
    return sourceList
  }

  const sourceSrcSet = ({ srcArr, width }) => {
    const def = imgSrcSetsize({ src: srcArr[0][0], width })
    let result
    srcArr.forEach((ele) => {
      var img = ele[0]
      if (ele.length > 1 && parseInt(ele[1], 10) >= width) {
        result = imgSrcSetsize({ src: img, width })
      }
    })
    const srcset = result || def
    return (
      <source key={width} srcSet={srcset} media={`(max-width: ${width}px)`} />
    )
  }

  const imgSrcSetsize = ({ src, width }) => {
    const size = customSize || parseInt(width * quality, 10)
    return src
      ?.split('/')
      ?.map((item) => {
        if (
          isShopifycdn(src) &&
          /\.(png|apng|jpg|jpeg|gif|svg|webp|tif)(\?.*)?/.test(item) &&
          /_[0-9]{2,4}x/.test(src) === false
        ) {
          const name = item.split('.')
          return name
            .map((v, i) => {
              if (i === name.length - 2) {
                return `${v}_${size < 3960 ? size : 3980}x`
              }
              return v
            })
            .join('.')
        }
        return item
      })
      .join('/')
  }

  const isShopifycdn = (src) => {
    const domains = images?.domains || []
    return domains.some((domain) => src.includes(domain))
  }

  return (
    <picture className={`block overflow-hidden ${className}`} {...rest}>
      {pictureLoader({ source })}
      {children}
    </picture>
  )
}
export default Picture
