import s from './index.module.css'
import cn from 'classnames'
import Slider from 'react-slick'
import Modal from 'react-modal'
import Icon from '@components/icons'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { Text } from '@components/ui'
import { Picture } from '@components/ui'
import { Close } from '@components/icons/next'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const Announcement = ({ metafields }) => {
  const { infos } = metafields
  const { locale } = useRouter()
  const [txtModal, setTxtModal] = useState('')
  const { setRelativeLink } = useRelativeLink()
  const SampleNextArrow = (props) => {
    const { onClick } = props
    return (
      <div
        onClick={onClick}
        className="absolute top-0 right-[1rem] z-10 block w-[50px] cursor-pointer"
      >
        <Picture
          quality={2}
          className={s.arrowsImg}
          source={`${'https://cdn.shopify.com/s/files/1/0493/9834/9974/files/358000854fe0ad24249b85f64640f9a4.png?v=1638970345'} 769`}
        />
      </div>
    )
  }
  const SamplePrevArrow = (props) => {
    const { onClick } = props
    return (
      <div
        onClick={onClick}
        className="absolute top-0 left-[1rem] z-10 block w-[50px] cursor-pointer"
      >
        <Picture
          quality={2}
          className={s.arrowsImg}
          source={`${'https://cdn.shopify.com/s/files/1/0493/9834/9974/files/fd04e833fb45ca7c14d25a877d4c9581.png?v=1638970345'} 769`}
        />
      </div>
    )
  }
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
    ],
  }

  const Item = ({ item }) => {
    if (item.link) {
      return (
        <div className={cn(s.announcement)}>
          {item.img ? (
            <Picture
              quality={2}
              className="w-[24px]"
              source={`${item.img} 769`}
            />
          ) : (
            <Icon iconKey={item.icon} width={24} />
          )}
          <a
            href={setRelativeLink({ link: item.link })}
            className={s.barText}
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </div>
      )
    }
    return (
      <div className={cn(s.announcement)}>
        <Icon iconKey={item.icon} width={24} />
        <button
          className={s.barText}
          dangerouslySetInnerHTML={{ __html: item.text }}
          onClick={() => setTxtModal(item.txtModal)}
        />
      </div>
    )
  }
  // 部分 slider 需要在移动端隐藏
  return infos?.showAnnouncements ? (
    <section className="mx-auto w-[95%] max-w-[1200px]">
      <Slider {...settings} className="only-in-pc">
        {infos.announcements
          ?.filter((item) => !item.mobileOnly)
          ?.map((item) => (
            <Item key={item.text} item={item} />
          ))}
      </Slider>
      <Slider {...settings} className="only-in-mobile">
        {infos.announcements
          ?.filter((item) => !item.pcOnly)
          ?.map((item) => (
            <Item key={item.text} item={item} />
          ))}
      </Slider>
      <Modal
        isOpen={!!txtModal}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="coupon-faq"
        onRequestClose={() => setTxtModal('')}
      >
        <Close className={s.closeBtn} onClick={() => setTxtModal('')} />
        <Text className={cn(s.couponTxt)} html={txtModal} />
      </Modal>
    </section>
  ) : (
    ''
  )
}

export default Announcement
